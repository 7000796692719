import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import { addJobs, CreateTestimonials } from '../services/allApi';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';

const TestimonialAddModal = ({ show, onHide, getallJobs, setLoading }) => {
    const [formData, setFormData] = useState({
        Name: '',
        company: '',
        image: '',
        description: '',
    });
    const [imagePreview, setImagePreview] = useState(null); // For displaying the image preview
    const [selectedFile, setSelectedFile] = useState(null); // For storing the actual file object
    const [error, setError] = useState(''); // For displaying error messages

    const validateFileType = (file) => {
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
        return allowedTypes.includes(file.type);
    };


    const HandleAddTestimonials = async () => {
        setLoading(true);

        const { Name, company, description } = formData;

        // Basic Validation
        if (!Name || !description) {
            Swal.fire({
                title: 'Incomplete Data!',
                text: 'Please fill in both the Name and Description fields.',
                icon: 'warning',
                confirmButtonText: 'Done',
            });
            setLoading(false);
            return;
        }

        // if (!selectedFile) {
        //     Swal.fire({
        //         title: 'Missing Image!',
        //         text: 'Please upload an image file.',
        //         icon: 'warning',
        //         confirmButtonText: 'Done',
        //     });
        //     setLoading(false);
        //     return;
        // }

        // Create FormData object
        const formDataToSend = new FormData();
        formDataToSend.append('Name', Name);
        formDataToSend.append('company', company);
        formDataToSend.append('description', description);
        if(selectedFile){
            formDataToSend.append('image', selectedFile);
        }

        // Authorization Header
        const token = localStorage.getItem('resourceZone_token');
        const ReqHeader = {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
        };

        try {
            const result = await CreateTestimonials(formDataToSend, ReqHeader);
            if (result.status === 201) {
                Swal.fire({
                    title: 'Success!',
                    text: 'Testimonial has been created successfully.',
                    icon: 'success',
                    confirmButtonText: 'Done',
                });


                setFormData({
                    Name: '',
                    company: '',
                    image: '',
                    description: '',
                });
                setSelectedFile(null);
                setImagePreview(null);


                setTimeout(() => {
                    getallJobs();
                }, 1000);


                onHide();
            }
        } catch (error) {
            console.error('Error creating testimonial:', error);
            Swal.fire({
                title: 'Error!',
                text: 'Something went wrong. Please try again later.',
                icon: 'error',
                confirmButtonText: 'Done',
            });
        } finally {
            setLoading(false);
        }
    };

    const handlecancel = () => {
        onHide()
        setFormData({
            Name: '',
            company: '',
            image: '',
            description: '',
        })
    }

    console.log(formData)
    return (
        <>
            <Modal
                show={show}
                onHide={handlecancel}
                size="md"
                centered
                contentClassName="border-0"
            >
                <Modal.Header className="border-0 pb-0">
                    <Modal.Title className="text-2xl font-normal">Add Testimonial</Modal.Title>
                </Modal.Header>

                <Modal.Body className="">
                    <Form>
                        <Form.Group className="mb-2">
                            <Form.Label className="text-lg mb-2">Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter  Name"
                                className="py-3 px-4 border rounded-lg text-gray-500"
                                value={formData.Name}
                                onChange={(e) => setFormData({ ...formData, Name: e.target.value })}
                            />
                        </Form.Group>

                        <Form.Group className="mb-2">
                            <Form.Label className="text-lg mb-2">Venture</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Venture Name"
                                className="py-3 px-4 border rounded-lg"
                                value={formData.company}
                                onChange={(e) => setFormData({ ...formData, company: e.target.value })}
                            />
                        </Form.Group>

                        {/* <Form.Group className="mb-2">
              <Form.Label className="text-lg mb-2">Country</Form.Label>
              <Form.Control
                type="text"
                className="py-3 px-4 border rounded-lg"
                value={formData.country}
                onChange={(e) => setFormData({ ...formData, country: e.target.value })}
              />
            </Form.Group> */}
                        <Form.Group className="mb-4">
                            <Form.Label className="mb-2">Description</Form.Label>
                            <Form.Control
                                as="textarea" // Change input type to textarea
                                rows={4} // Set the number of rows for the textarea
                                placeholder="Enter Description"
                                className="py-3 px-4 border rounded text-gray-500"
                                value={formData.description}
                                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                            />
                        </Form.Group>

                        <Form.Group className="mb-4">
                            <Form.Label className="mb-2">Upload Image</Form.Label>
                            <Form.Control
                                type="file"
                                accept="image/*"
                                onChange={(e) => {
                                    const file = e.target.files[0];
                                    if (file) {
                                        if (validateFileType(file)) {
                                            setSelectedFile(file); // Store the file
                                            setImagePreview(URL.createObjectURL(file)); // Generate a preview URL
                                            setError(''); // Clear any previous error
                                        } else {
                                            setError('Invalid file type. Only PNG, JPG, and JPEG are allowed.');
                                            setSelectedFile(null); // Reset file
                                            setImagePreview(null); // Reset preview
                                        }
                                    }
                                }}
                            />
                            {error && <div className="text-danger mt-2">{error}</div>} {/* Display error */}
                            {imagePreview && (
                                <div className="mt-3 position-relative">
                                    <img
                                        src={imagePreview}
                                        alt="Preview"
                                        className="img-fluid border rounded"
                                        style={{ width: '100%', height: 'auto' }}
                                    />
                                    <button
                                        type="button"
                                        className="btn btn-danger btn-sm position-absolute top-0 end-0 m-1"
                                        onClick={() => {
                                            setImagePreview(null); 
                                            setSelectedFile(null); 
                                        }}
                                    >
                                        ✕
                                    </button>
                                </div>
                            )}
                        </Form.Group>


                    </Form>
                </Modal.Body>

                <Modal.Footer className="border-0 px-4 pb-4">
                    <Button
                        variant="light"
                        onClick={handlecancel}
                        className="px-8 py-2 rounded mr-3 font-normal"
                        style={{ backgroundColor: '#fff', border: '1px solid #e2e8f0' }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="success"
                        className="px-8 py-2 rounded font-normal"
                        style={{ backgroundColor: '#10b981', border: 'none' }}
                        onClick={HandleAddTestimonials}

                    >
                        Add Testimonial
                    </Button>
                </Modal.Footer>
            </Modal>
            <ToastContainer />
        </>

    );
};

export default TestimonialAddModal;


