import React, { useState, useEffect } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { editJobs, editTestimonials } from '../services/allApi';
import { toast, ToastContainer } from 'react-toastify';
import { FormControlLabel, RadioGroup, FormLabel, FormControl, Radio } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import Dialog from '@mui/material/Dialog';
import { useTheme } from '@mui/material/styles';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { BASE_URL } from '../services/baseUrl';

const EditTestimonailModal = ({ showEdit, onEditHide, getallJobs, SelectedJob, setLoading }) => {
    const [formData, setFormData] = useState({
        Name: '',
        company: '',
        image: '',
        description: '',
    });
    const [imagePreview, setImagePreview] = useState(null); 
    const [selectedFile, setSelectedFile] = useState(null);
    const [error, setError] = useState(''); 

  const [dialogopen, setDialogopen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const validateFileType = (file) => {
    const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
    return allowedTypes.includes(file.type);
};

  useEffect(() => {
    if (SelectedJob) {
      setFormData({
        Name: SelectedJob.Name,
        company: SelectedJob.company,
        image: SelectedJob.image,
        description: SelectedJob.description,
       
      });
    }
  }, [SelectedJob]);

  const handleDialogOpen = () => {
    setDialogopen(true);
  };

  const handleDialogClose = () => {
    setDialogopen(false);
  };


  const handleEditTestimonial = async (id) => {
    setLoading(true);
    
    const { Name, company, description } = formData;
    const formDataToSend = new FormData();
  
    // Append only fields that have values
    if (Name) formDataToSend.append('Name', Name);
    if (company) formDataToSend.append('company', company);
    if (description) formDataToSend.append('description', description);
  
    // Append image only if a new file is selected
    if (selectedFile && validateFileType(selectedFile)) {
      formDataToSend.append('image', selectedFile);
    }
  
    const token = localStorage.getItem('resourceZone_token');
    const reqHeader = {
       'Content-Type': 'multipart/form-data', 
      'Authorization': `Bearer ${token}`
    };
  
    try {
      const result = await editTestimonials(id, formDataToSend, reqHeader);
      if (result.status === 200) {
        Swal.fire({
          title: 'Success!',
          text: 'Testimonial has been updated successfully.',
          icon: 'success',
          confirmButtonText: 'Done'
        });
        setTimeout(() => {
            getallJobs(); 
        }, 1000);
        onEditHide(); 
      }
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: 'Something went wrong during the update.',
        icon: 'error',
        confirmButtonText: 'Okay'
      });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  

  const handleCancel = () => {
    onEditHide();
    setFormData({
        Name: '',
        company: '',
        image: '',
        description: '',
    });
  };

  return (
    <>
      <Modal
        show={showEdit}
        onHide={handleCancel}
        size="md"
        centered
        contentClassName="border-0"
      >
        <Modal.Header className="border-0 pb-0">
          <Modal.Title className="text-2xl font-normal">Edit Testimonial</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group className="mb-2">
              <Form.Label className="text-lg mb-2">Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Job Role Name"
                className="py-3 px-4 border rounded-lg text-gray-500"
                value={formData.Name}
                onChange={(e) => setFormData({ ...formData, Name: e.target.value })}
              />
            </Form.Group>

            <Form.Group className="mb-2">
              <Form.Label className="text-lg mb-2">Venture</Form.Label>
              <Form.Control
                type="text"
                className="py-3 px-4 border rounded-lg"
                value={formData.company}
                onChange={(e) => setFormData({ ...formData, company: e.target.value })}
              />
            </Form.Group>

            <Form.Group className="mb-2">
              <Form.Label className="text-lg mb-2">Description</Form.Label>
              <Form.Control
               as="textarea" // Change input type to textarea
               rows={4} // Set the number of rows for the textarea
                type="text"
                className="py-3 px-4 border rounded-lg text-gray-500"
                value={formData.description}
                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                placeholder="Enter country"
              />
            </Form.Group>
            <Form.Group className="mb-4">
    <Form.Label className="mb-2">Upload Image</Form.Label>
    <Form.Control
      type="file"
      accept="image/*"
      onChange={(e) => {
        const file = e.target.files[0];
        if (file) {
          if (validateFileType(file)) {
            setSelectedFile(file); // Store the file
            setImagePreview(URL.createObjectURL(file)); // Generate a preview URL
            setError(''); // Clear any previous error
          } else {
            setError('Invalid file type. Only PNG, JPG, and JPEG are allowed.');
            setSelectedFile(null); // Reset file
            setImagePreview(null); // Reset preview
          }
        }
      }}
    />
    {error && <div className="text-danger mt-2">{error}</div>}
    {/* Display Existing or Previewed Image */}
    {(imagePreview || formData.image) && (
      <div className="mt-3 position-relative">
        <img
          src={imagePreview || `${BASE_URL}/documents/${formData.image}`}
          alt="Preview"
          className="img-fluid border rounded"
          style={{ width: '100%', height: 'auto' }}
        />
        <button
          type="button"
          className="btn btn-danger btn-sm position-absolute top-0 end-0 m-1"
          onClick={() => {
            setImagePreview(null); 
            setSelectedFile(null); 
            if (imagePreview) setFormData({ ...formData, image: '' }); 
          }}
        >
          ✕
        </button>
      </div>
    )}
  </Form.Group>

            

            

        
          </Form>
        </Modal.Body>

        <Modal.Footer className="border-0 px-4 pb-4">
          <Button
            variant="light"
            onClick={handleCancel}
            className="px-8 py-2 rounded mr-3 font-normal"
            style={{ backgroundColor: '#fff', border: '1px solid #e2e8f0' }}
          >
            Cancel
          </Button>
          <Button
            variant="success"
            className="px-8 py-2 rounded font-normal"
            style={{ backgroundColor: '#10b981', border: 'none' }}
            onClick={handleDialogOpen}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
       {/* edit Confirmation Dialog */}
       <Dialog
  open={dialogopen}
  onClose={handleDialogClose}
  fullScreen={fullScreen}
>
  <DialogTitle>Confirm Edit</DialogTitle>
  <DialogContent>
    <DialogContentText>
      Are you sure you want to Save the changes?
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button
      style={{
        backgroundColor: 'red',
        color: 'white',
        border: 'none',
        padding: '8px 16px',
        fontWeight: 'bold',
        borderRadius: '4px',
      }}
      onClick={handleDialogClose}
    >
      Cancel
    </Button>
    <Button
      onClick={() => handleEditTestimonial(SelectedJob._id)}
      autoFocus
      style={{
        backgroundColor: 'green',
        color: 'white',
        border: 'none',
        padding: '8px 16px',
        fontWeight: 'bold',
        borderRadius: '4px',
      }}
    >
      Yes, Confirm
    </Button>
  </DialogActions>
</Dialog>

      <ToastContainer />
    </>
  );
};

export default EditTestimonailModal;
