import React from 'react'
import Hrm1 from '../components/Hrm1'
import Hrmservice from '../components/Hrmservice'
import Note from './Note'

function Hrm() {
  return (
    <div>
        <Hrm1></Hrm1>
        <Hrmservice></Hrmservice>
        <Note></Note>

       
    </div>
  )
}

export default Hrm